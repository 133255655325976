import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/dev-site/dev-site/src/templates/Page/index.ts";
import BinaryDatabases from './_binary-databases.mdx';
import ConversionUtility from './_conversion-utility.mdx';
import CoordinatesWarning from './_coordinates-warning.mdx';
import CsvDatabasesIntro from './_csv-databases-intro.mdx';
import DataPrivacyWarning from './_data-privacy-warning.mdx';
import ExampleFiles from './_example-files.mdx';
import FileFormat from './_file-format.mdx';
import IpGeolocationUsageWarning from './_ip-geolocation-usage-warning.mdx';
import ReturnedValuesAsKeys from './_returned-values-as-keys.mdx';
import APIs from './_apis.mdx';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ZipFileContent = makeShortcode("ZipFileContent");
const CsvBlockTable = makeShortcode("CsvBlockTable");
const CsvLocationTable = makeShortcode("CsvLocationTable");
const CsvFileExamples = makeShortcode("CsvFileExamples");
const MmdbFileExamples = makeShortcode("MmdbFileExamples");
const DatabaseSizes = makeShortcode("DatabaseSizes");
const DatabaseChanges = makeShortcode("DatabaseChanges");
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <p>{`Determine the country, subdivisions (regions), city, and postal code associated
with IPv4 and IPv6 addresses worldwide.`}</p>
    <p>{`Terms and pricing for the GeoIP2 City and GeoIP2 Country databases may be found
on our product pages:`}</p>
    <ul>

      <li {...{
        "parentName": "ul"
      }}><a {...{
          "href": "https://www.maxmind.com/en/geoip2-city",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "li"
        }}>{`GeoIP2 City`}</a></li>


      <li {...{
        "parentName": "ul"
      }}><a {...{
          "href": "https://www.maxmind.com/en/geoip2-country",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "li"
        }}>{`GeoIP2 Country`}</a></li>

    </ul>
    <IpGeolocationUsageWarning mdxType="IpGeolocationUsageWarning" />
    <DataPrivacyWarning mdxType="DataPrivacyWarning" />
    <div {...{
      "id": "toc-binary-databases"
    }}><h2 {...{
        "id": "binary-databases",
        "parentName": "div"
      }}>{`Binary Databases`}</h2>
      <BinaryDatabases mdxType="BinaryDatabases" />
      <table {...{
        "parentName": "div"
      }}>

        <thead {...{
          "parentName": "table"
        }}>

          <tr {...{
            "parentName": "thead"
          }}>

            <th {...{
              "parentName": "tr"
            }}>{`Language or Framework`}</th>


            <th {...{
              "parentName": "tr"
            }}>{`Documentation`}</th>


            <th {...{
              "parentName": "tr"
            }}>{`Example`}</th>

          </tr>

        </thead>


        <tbody {...{
          "parentName": "table"
        }}>

          <tr {...{
            "parentName": "tbody"
          }}>

            <td {...{
              "parentName": "tr"
            }}>{`.NET (C#)`}</td>


            <td {...{
              "parentName": "tr"
            }}><a {...{
                "href": "https://github.com/maxmind/GeoIP2-dotnet#database-usage",
                "target": "_blank",
                "rel": "nofollow noopener noreferrer",
                "parentName": "td"
              }}>{`GitHub - Database Usage`}</a></td>


            <td {...{
              "parentName": "tr"
            }}><a {...{
                "href": "https://github.com/maxmind/GeoIP2-dotnet#city-database",
                "target": "_blank",
                "rel": "nofollow noopener noreferrer",
                "parentName": "td"
              }}>{`City Example`}</a></td>

          </tr>


          <tr {...{
            "parentName": "tbody"
          }}>

            <td {...{
              "parentName": "tr"
            }}>{`Java`}</td>


            <td {...{
              "parentName": "tr"
            }}><a {...{
                "href": "https://github.com/maxmind/GeoIP2-java#database-usage",
                "target": "_blank",
                "rel": "nofollow noopener noreferrer",
                "parentName": "td"
              }}>{`GitHub - Database Usage`}</a></td>


            <td {...{
              "parentName": "tr"
            }}><a {...{
                "href": "https://github.com/maxmind/GeoIP2-java#city",
                "target": "_blank",
                "rel": "nofollow noopener noreferrer",
                "parentName": "td"
              }}>{`City Example`}</a></td>

          </tr>


          <tr {...{
            "parentName": "tbody"
          }}>

            <td {...{
              "parentName": "tr"
            }}>{`Node.js`}</td>


            <td {...{
              "parentName": "tr"
            }}><a {...{
                "href": "https://github.com/maxmind/GeoIP2-node#database-usage",
                "target": "_blank",
                "rel": "nofollow noopener noreferrer",
                "parentName": "td"
              }}>{`GitHub - Database Usage`}</a></td>


            <td {...{
              "parentName": "tr"
            }}><a {...{
                "href": "https://github.com/maxmind/GeoIP2-node#city-example",
                "target": "_blank",
                "rel": "nofollow noopener noreferrer",
                "parentName": "td"
              }}>{`City Example`}</a></td>

          </tr>


          <tr {...{
            "parentName": "tbody"
          }}>

            <td {...{
              "parentName": "tr"
            }}>{`PHP`}</td>


            <td {...{
              "parentName": "tr"
            }}><a {...{
                "href": "https://github.com/maxmind/GeoIP2-php#database-reader",
                "target": "_blank",
                "rel": "nofollow noopener noreferrer",
                "parentName": "td"
              }}>{`GitHub - Database Reader`}</a></td>


            <td {...{
              "parentName": "tr"
            }}><a {...{
                "href": "https://github.com/maxmind/GeoIP2-php#city-example",
                "target": "_blank",
                "rel": "nofollow noopener noreferrer",
                "parentName": "td"
              }}>{`City Example`}</a></td>

          </tr>


          <tr {...{
            "parentName": "tbody"
          }}>

            <td {...{
              "parentName": "tr"
            }}>{`Python`}</td>


            <td {...{
              "parentName": "tr"
            }}><a {...{
                "href": "https://github.com/maxmind/GeoIP2-python#database-usage",
                "target": "_blank",
                "rel": "nofollow noopener noreferrer",
                "parentName": "td"
              }}>{`GitHub - Database Usage`}</a></td>


            <td {...{
              "parentName": "tr"
            }}><a {...{
                "href": "https://github.com/maxmind/GeoIP2-python#city-database",
                "target": "_blank",
                "rel": "nofollow noopener noreferrer",
                "parentName": "td"
              }}>{`City Example`}</a></td>

          </tr>


          <tr {...{
            "parentName": "tbody"
          }}>

            <td {...{
              "parentName": "tr"
            }}>{`Ruby`}</td>


            <td {...{
              "parentName": "tr"
            }}><a {...{
                "href": "https://github.com/maxmind/GeoIP2-ruby#database-reader",
                "target": "_blank",
                "rel": "nofollow noopener noreferrer",
                "parentName": "td"
              }}>{`GitHub - Database Reader`}</a></td>


            <td {...{
              "parentName": "tr"
            }}><a {...{
                "href": "https://github.com/maxmind/GeoIP2-ruby#city-example",
                "target": "_blank",
                "rel": "nofollow noopener noreferrer",
                "parentName": "td"
              }}>{`City Example`}</a></td>

          </tr>

        </tbody>

      </table></div>
    <div {...{
      "id": "toc-csv-databases"
    }}><h2 {...{
        "id": "csv-databases",
        "parentName": "div"
      }}>{`CSV Databases`}</h2>
      <CsvDatabasesIntro mdxType="CsvDatabasesIntro" />
      <ZipFileContent productName="{GeoIP2,GeoLite2}-{City,Country}" isTableExcluded={true} mdxType="ZipFileContent" />
      <p {...{
        "parentName": "div"
      }}>{`The downloaded .zip file contains a single directory which in turn contains 13
files:`}</p>
      <table>
        <tbody>
          <tr>
            <th>
              <p>{`Filename`}</p>
            </th>
            <th>
              <p>{`Description`}</p>
            </th>
          </tr>
          <tr>
            <td>
              <p><inlineCode {...{
                  "parentName": "p"
                }}>{`COPYRIGHT.txt`}</inlineCode></p>
            </td>
            <td>
              <p>{`Copyright statement`}</p>
            </td>
          </tr>
          <tr>
            <td>
              <p><inlineCode {...{
                  "parentName": "p"
                }}>{`LICENSE.txt`}</inlineCode></p>
            </td>
            <td>
              <p>{`End user license`}</p>
            </td>
          </tr>
          <tr>
            <td>
              <p><inlineCode {...{
                  "parentName": "p"
                }}>{`README.txt`}</inlineCode></p>
            </td>
            <td>
              <p>{`Information file`}</p>
            </td>
          </tr>
          <tr>
            <td>
              <p><inlineCode {...{
                  "parentName": "p"
                }}>{`{GeoIP2,GeoLite2}-{City,Country}-Blocks-IPv4.csv`}</inlineCode></p>
            </td>
            <td>
              <p>{`CSV file containing data on IPv4 addresses`}</p>
            </td>
          </tr>
          <tr>
            <td>
              <p><inlineCode {...{
                  "parentName": "p"
                }}>{`{GeoIP2,GeoLite2}-{City,Country}-Blocks-IPv6.csv`}</inlineCode></p>
            </td>
            <td>
              <p>{`CSV file containing data on IPv6 addresses`}</p>
            </td>
          </tr>
          <tr>
            <td>
              <p><inlineCode {...{
                  "parentName": "p"
                }}>{`{GeoIP2,GeoLite2}-{City,Country}-Locations-{locale}.csv`}</inlineCode></p>
            </td>
            <td>
              <p>{`Eight CSV files containing data location for each locale code, where `}
                <inlineCode {...{
                  "parentName": "p"
                }}>{`{locale}`}</inlineCode>
                {` can be “de”, "en", “es”, “fr”, “ja”, “pt-BR”, “ru”, and “zh-CN” .`}</p>
            </td>
          </tr>
        </tbody>
      </table>
      <FileFormat mdxType="FileFormat" />
      <div {...{
        "id": "toc-blocks-files",
        "parentName": "div"
      }}><h3 {...{
          "id": "blocks-files",
          "parentName": "div"
        }}>{`Blocks Files`}</h3>
        <p {...{
          "parentName": "div"
        }}>{`There are two CSV files for network blocks, one each for IPv4 and IPv6 blocks.
These files are named `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`{GeoIP2,GeoLite2}-{City,Country}-Blocks-IPv4.csv`}</inlineCode>
          {` and
`}
          <inlineCode {...{
            "parentName": "p"
          }}>{`{GeoIP2,GeoLite2}-{City,Country}-Blocks-IPv6.csv`}</inlineCode>
          {` respectively. An example name
would be `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`GeoIP2-City-Blocks-IPv4.csv`}</inlineCode>
          {`.`}</p>
        <p {...{
          "parentName": "div"
        }}><a {...{
            "href": "https://www.maxmind.com/en/geoip2-city-database-by-continent",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`City subset by continent databases`}</a>
          {`
blocks files have the same columns as the City database.`}</p>
        <CsvBlockTable mdxType="CsvBlockTable" />
        <CoordinatesWarning mdxType="CoordinatesWarning" /></div>
      <div {...{
        "id": "toc-locations-files",
        "parentName": "div"
      }}><h3 {...{
          "id": "locations-files",
          "parentName": "div"
        }}>{`Locations Files`}</h3>
        <p {...{
          "parentName": "div"
        }}>{`The zip file includes one location file for each locale code for which data is
available. There will always be an "en" file containing a record for every
`}
          <inlineCode {...{
            "parentName": "p"
          }}>{`geoname_id`}</inlineCode>
          {` used in the blocks files. Files corresponding to other locales will
exist and have a record for a `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`geoname_id`}</inlineCode>
          {` only if data is available. The other
possible locales are "de", "es", "fr", "ja", "pt-BR", "ru", and "zh-CN".`}</p>
        <p {...{
          "parentName": "div"
        }}>{`These files are named `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`{GeoIP2,GeoLite2}-{City,Country}-Locations-{locale}.csv`}</inlineCode>
          {`.
An example name would be `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`GeoIP2-City-Locations-en.csv`}</inlineCode>
          {`.`}</p>
        <p {...{
          "parentName": "div"
        }}><a {...{
            "href": "https://www.maxmind.com/en/geoip2-city-database-by-continent",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`City subset by continent databases`}</a>
          {`
blocks files have the same columns as the City database.`}</p>
        <CsvLocationTable mdxType="CsvLocationTable" /></div>
      <div {...{
        "id": "toc-returned-values-as-database-map-dict-or-hash-keys",
        "parentName": "div"
      }}><h3 {...{
          "id": "returned-values-as-database-map-dict-or-hash-keys",
          "parentName": "div"
        }}>{`Returned Values as Database, Map, Dict, or Hash Keys`}</h3>
        <ReturnedValuesAsKeys mdxType="ReturnedValuesAsKeys" /></div>
      <div {...{
        "id": "toc-conversion-utility",
        "parentName": "div"
      }}><h3 {...{
          "id": "conversion-utility",
          "parentName": "div"
        }}>{`Conversion Utility`}</h3>
        <ConversionUtility mdxType="ConversionUtility" /></div></div>
    <div {...{
      "id": "toc-example-files"
    }}><h2 {...{
        "id": "example-files",
        "parentName": "div"
      }}>{`Example Files`}</h2>
      <ExampleFiles mdxType="ExampleFiles" />
      <div {...{
        "id": "toc-csv-example-files",
        "parentName": "div"
      }}><h3 {...{
          "id": "csv-example-files",
          "parentName": "div"
        }}>{`CSV Example Files`}</h3>
        <CsvFileExamples files={[{
          filename: 'GeoIP2-City-CSV_Example.zip',
          link: '/static/GeoIP2-City-CSV_Example.zip'
        }, {
          filename: 'GeoIP2-Country-CSV_Example.zip',
          link: '/static/GeoIP2-Country-CSV_Example.zip'
        }]} mdxType="CsvFileExamples" /></div>
      <div {...{
        "id": "toc-mmdb-example-files",
        "parentName": "div"
      }}><h3 {...{
          "id": "mmdb-example-files",
          "parentName": "div"
        }}>{`MMDB Example Files`}</h3>
        <MmdbFileExamples files={[{
          filename: 'GeoIP2-City-Test.mmdb',
          link: 'https://github.com/maxmind/MaxMind-DB/blob/main/test-data/GeoIP2-City-Test.mmdb'
        }, {
          filename: 'GeoIP2-Country-Test.mmdb',
          link: 'https://github.com/maxmind/MaxMind-DB/blob/main/test-data/GeoIP2-Country-Test.mmdb'
        }]} mdxType="MmdbFileExamples" /></div></div>
    <div {...{
      "id": "toc-database-sizes"
    }}><h2 {...{
        "id": "database-sizes",
        "parentName": "div"
      }}>{`Database Sizes`}</h2>
      <DatabaseSizes dateRange="March to June 2024" databaseChanges={[{
        databaseName: 'GeoIP City',
        csvSizeRange: '577 MB - 606 MB',
        mmdbSizeRange: '108 MB - 110 MB',
        ipv4Range: '657,000 - 682,000',
        ipv6Range: '231,000 - 250,000'
      }, {
        databaseName: 'GeoIP Country',
        csvSizeRange: '29.2 MB - 30.8 MB',
        mmdbSizeRange: '6.29 MB - 6.53 MB',
        ipv4Range: '480,000 - 502,000',
        ipv6Range: '265,000 - 280,000'
      }, {
        databaseName: 'GeoLite City',
        csvSizeRange: '226.2 MB - 292.7 MB',
        mmdbSizeRange: '50 MB - 63.8 MB',
        ipv4Range: '236,000 - 334,000',
        ipv6Range: '114,000 - 123,000'
      }, {
        databaseName: 'GeoLite Country',
        csvSizeRange: '30 MB - 31.7 MB',
        mmdbSizeRange: '6.38 MB - 6.64 MB',
        ipv4Range: '480,000 - 490,000',
        ipv6Range: '280,000 - 308,000'
      }]} mdxType="DatabaseSizes" /></div>
    <div {...{
      "id": "toc-database-changes"
    }}><h2 {...{
        "id": "database-changes",
        "parentName": "div"
      }}>{`Database Changes`}</h2>
      <DatabaseChanges product="City and Country" mdxType="DatabaseChanges" /></div>
    <div {...{
      "id": "toc-apis-and-third-party-integrations"
    }}><h2 {...{
        "id": "apis-and-third-party-integrations",
        "parentName": "div"
      }}>{`APIs and Third-Party Integrations`}</h2>
      <APIs mdxType="APIs" /></div>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      